import { all, call, fork, put, take, takeLatest } from '@redux-saga/core/effects'
import * as CustomerServices from '../../services/customer.services'
import history from '../../history'
import * as actionTypes from '../../constants/ActionType'
import {
  setCustomerInfo,
  setAuth,
  setHasLoggedInByZaloOa,
  setShowSplashing,
  loginWithZalopay
} from '../../actions/auth.action'
import { REACT_APP_ON_ZALOPAY } from '../../constants/Env'
import { ZALO_REDIRECT } from '../../constants/Config'
import store from '../../store'
import * as AuthServices from '../../services/auth.services'
import { checkBinding } from '../../actions/autodebit.action'

function handleCustomerInfoError() {
  sessionStorage.removeItem('Session')
  document.cookie = ''
  if (window.ZaloPay.isZaloPay) {
    // store.dispatch(loginWithZalopay())
  } else {
    window.location.href = ZALO_REDIRECT
  }
}

function* handleGetCustomerInfo({ onFinish }) {
  try {
    const data = yield call(CustomerServices.getCustomerInfo)
    if (!data || data.code === 5 || data.error.code === 5) {
      //invalid session, clear session
      //Redirect to zalo login
      handleCustomerInfoError()
    } else if (data.error.code === 1) {
      const customerInfo = data.data
      const customerData = {
        customerPhone: customerInfo.phone,
        customerId: customerInfo.customer_id
      }

      const authData = {
        account_name: customerInfo.account_name,
        customer_id: customerInfo.customer_id,
        customer_type: customerInfo.customer_type,
        registration_confirm_status: customerInfo.registration_confirm_status,
        avatar: customerInfo.avatar,
        phone: customerInfo.phone,
        card_no: customerInfo.card_no,
        user_card_type: customerInfo.user_card_type,
        qr_tk: customerInfo.qr_tk,
        card_type: customerInfo.card_type,
        customer_name: customerInfo.customer_name,
        card_status: customerInfo.status,
        promo_object_type: customerInfo.promo_object_type,
        promo_remaining_number: 0,
        is_active: customerInfo.is_active,
        expire_date: customerInfo.expire_date,
        is_linked: false,
        received_address: customerInfo.received_address,
        student_card_image: customerInfo.student_card_image,
        date_update: customerInfo.date_update,
        reject_description: customerInfo.reject_description,
        privacy_policy_accepted: customerInfo.privacy_policy_accepted,
        privacy_policy_accept_updated: customerInfo.privacy_policy_accept_updated
        // ...customerInfo,
        // card_status: customerInfo.status,
      }
      if (onFinish) onFinish(authData)
      yield put(setCustomerInfo(customerData))
      yield put(setAuth(authData))
      yield put(setHasLoggedInByZaloOa(true))
      yield put(setShowSplashing(false))
      yield put(checkBinding())

      history.replace(`/`)
    } else {
      // yield put(setShowSplashing(false));
      // history.replace(`/error-page`);
      const ping = yield call(AuthServices.pingServer)
    }
  } catch (err) {
    // yield put(setShowSplashing(false));
    // history.replace(`/error-page`);
    const ping = yield call(AuthServices.pingServer)
    console.log(err)
    handleCustomerInfoError()
  }
}

export default function* authSaga() {
  yield takeLatest(actionTypes.GET_CUSTOMER_INFO, handleGetCustomerInfo)
}
